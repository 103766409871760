import React from 'react'

const ForgotPassword = () => {
  return (
    <div>
      ForgotPassword
    </div>
  );
};

export default ForgotPassword;