import React from 'react'

const Control = () => {
  return (
    <div>
      Conditional statements (if-else) for decision-making.
Looping constructs (for, while) for iterative tasks.
Using case statements for multi-branch decisions.
    </div>
  )
}

export default Control;